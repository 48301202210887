.crop-container {
    position: relative;
    height:100%;
    width:100%;
}
.p-dialog-header{
    padding:10px!important;
}
.p-dialog-footer,.p-dialog .p-dialog-content{
    padding: 0 10px 10px 10px;
}

