body {
  font-size: 14px;
  line-height: 1.428571429;
  color: #4b5563;;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-green {
  background-color: #50c550;
  color: black;
}

.css-18wi0sz>.ps-menu-button {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-radius: 10px;
  height: auto !important;
}

main {
  /* border:1px solid rgb(201, 196, 196) !important; */
  background-color: #fbfbfc !important;

}

.p-datatable .p-datatable-thead>tr>th {
  font-size: 14px;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  font-size: 14px;
}